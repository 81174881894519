import React, { useState, useEffect } from "react";
import { Select, Radio } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";

import ogImage from "../../images/global_assets/og-image.png";
import Heading from "../../components-v2/Base/HeadingBuilder";
import Section from "../../components-v2/Base/Section";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";

const links = {
  northwest: {
    english: "https://posdemorequest-northwest.youcanbook.me/",
    spanish: "https://posdemorequest-spanish.youcanbook.me/",
  },
  southwest: {
    english: "https://posdemorequest-southwest.youcanbook.me/",
    spanish: "https://posdemorequest-spanish.youcanbook.me/",
  },
  mid_atlantic: {
    english: "https://posdemorequest-midatlantic.youcanbook.me/",
    spanish: "https://posdemorequest-spanish.youcanbook.me/",
  },
  nyc_tristate: {
    english: "https://posdemorequest-tristate.youcanbook.me/",
    spanish: "https://posdemorequest-spanish.youcanbook.me/",
  },
  southeast: {
    english: "https://posdemorequest-southeast.youcanbook.me/",
    spanish: "https://posdemorequest-spanish.youcanbook.me/",
  },
  south: {
    english: "https://posdemorequest-south.youcanbook.me/",
    spanish: "https://posdemorequest-south.youcanbook.me/",
  },
};
const Legal = () => {
  const [region, setRegion] = useState("");
  const [language, setLanguage] = useState("");
  const [regionError, setRegionError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [link, setLink] = useState("");

  useEffect(() => {
    if (!region || !language) {
      return;
    }

    setLink(links[region][language]);
  }, [region, language]);

  const onRegionChange = (value) => {
    setRegion(value);
  };

  const onLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const onNextClick = () => {
    if (!region || !language) {
      if (!region) {
        setRegionError(true);
      } else {
        setRegionError(false);
      }

      if (!language) {
        setLanguageError(true);
      } else {
        setLanguageError(false);
      }

      return;
    }

    setRegionError(false);
    setLanguageError(false);
    window.location.href = link;
  };

  return (
    <Layout className="about-us">
      <SEO
        title="SpotOn | AE Demo Schedule"
        description="SpotOn Account Executives, use the appropriate links below to request a private demo for one of your clients ."
        image={`https://spoton.com/${ogImage}`}
        noIndex
      />

      <Section className="mt-36 max-w-[900px] text-center">
        <Heading withBlueSymbol level={1} injectionType={1}>
          Please select your RVPs sales region and demo language
        </Heading>
      </Section>

      <Section className="mt-10">
        <div
          className="flex flex-col md:flex-row justify-center gap-x-10 lg:gap-x-20 gap-y-10 md:items-center py-[60px]"
          style={{
            borderTop: "1px solid #D6DADF",
            borderBottom: "1px solid #D6DADF",
          }}
        >
          <div className="">
            <div className="text-black text-[15px] mb-2">
              Select your sales region:
            </div>
            <Select
              className="w-full min-w-[300px] md:min-w-[350px]"
              onChange={onRegionChange}
              placeholder="Select region"
              options={[
                {
                  value: "northwest",
                  label: "Northwest",
                },
                {
                  value: "southwest",
                  label: "Southwest",
                },
                {
                  value: "mid_atlantic",
                  label: "Mid Atlantic",
                },
                {
                  value: "nyc_tristate",
                  label: "NYC, Tri-State",
                },
                {
                  value: "southeast",
                  label: "Southeast",
                },
                {
                  value: "south",
                  label: "South",
                },
              ]}
            />
            {!!regionError && (
              <div className="text-danger text-[12px] mt-1">
                Please select your sales region
              </div>
            )}
          </div>

          <div>
            <div className="text-black text-[15px] mb-2">
              Select demo language:
            </div>
            <Radio.Group onChange={onLanguageChange} value={language}>
              <Radio value="english">English</Radio>
              <Radio value="spanish">Spanish</Radio>
            </Radio.Group>
            {!!languageError && (
              <div className="text-danger text-[12px] mt-1">
                Please select a demo language
              </div>
            )}
          </div>
        </div>
      </Section>

      <Section className="flex justify-center -mt-4 mb-20">
        <CtaPrimary title="Next" onClick={onNextClick} />
      </Section>
    </Layout>
  );
};

export default Legal;
